// import { ArticleDetailsComponent } from './../admin/utilities/knowledgebase/article-details/article-details.component';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { API } from "./constant";
import { HttpClient, HttpParams } from "@angular/common/http";
import { MatDialogRef } from '@angular/material/dialog';
import { BaseResponse, IAutoAddArticleData } from '../admin/sales/proposals/interfaces/types';

@Injectable({
    providedIn: 'root'
})

export class KnowledgebaseService {
    public articlesDetail: ArticlesResult;
    public articleDetail: ArticlesResult;
    public updateArticleEventListener = new BehaviorSubject(0);
    public detailsArticleEventListener = new BehaviorSubject(0);
    public updateCategoryEventListener = new BehaviorSubject(0);
    public updategetCategoryArticlesEventListener = new BehaviorSubject(0);
    public kbCategory = new BehaviorSubject(null);
    articleDetailsId: any;
    categoryId;
    constructor(private http: HttpClient) {
        this.articlesDetail = {
            msg: 'knowledgebase',
            artId: 0,
            artCategoryId: 0,
            type: 'knowledgebase',
            url: 'admin/utilities/knowledgebase',
            articleDialog: null
        };
        this.articleDetail = {
            msg: 'knowledgebase',
            artId: 0,
            artCategoryId: 0,
            type: 'knowledgebase',
            url: 'knowledgebase',
            articleDialog: null
        };
     }
    
    getCategoryList() {
        return this.http.get(API.GET_KB_CATEGORIES_LIST);
    }

    createCategory(data: any) {
        return this.http.post(API.CREATE_KB_CATEGORY, data);
    }

    updateCategory(data: any) {
        return this.http.post(API.UPDATE_KB_CATEGORY, data);
    }

    createArticle(data: any) {
        return this.http.post(API.CREATE_KB_ARTICLE, data);
    }

    updateArticle(data: any) {
        return this.http.post(API.UPDATE_KB_ARTICLE, data);
    }

    addViewCount(data: any) {
        return this.http.post(API.ADD_VIEW, data);
    }
    addArticleViewCount(data: any) {
        return this.http.post(API.ADD_ARTICLE_VIEW, data);
    }

    addRating(data: any) {
        return this.http.post(API.ADD_RATING, data);
    }
    addRatingFromUser(data: any) {
        return this.http.post(API.ADD_RATING_FROM_USER, data);
    }

    getTree() {
        return this.http.get(API.GET_TREE);
    }

    getOverview(pid, searchString, filter) {
        let data = {};
        if (searchString != "") {
            data = {
                "catId":pid ? pid : 0,
                "searchString":searchString ? searchString : ""
            }
            var searchForStr = true;
        } else {
            var searchForStr = false;
        }
        data['filter'] = filter;
        if (pid && searchForStr) {
            return this.http.post(API.GET_OVERVIEW,data);
        } else if (pid && !searchForStr) {
            return this.http.post(API.GET_OVERVIEW,data);
            
        } else if (searchForStr) {
            return this.http.post(API.GET_OVERVIEW,data);
        } else {
            return this.http.post(API.GET_OVERVIEW,data);
        }
    }
    
    getPublicOverview(ck, isUbm,searchString, filter) {
        const obj = {
            ck:ck,
            isUbm:isUbm,
            searchString:searchString,
            "filter": filter
        }
        return this.http.post(API.GET_PUBLIC_OVERVIEW,obj);
    }

    setArticleDetailsId(artId){
        this.articleDetailsId = artId;
    }
    getArticleDetailsId(){
        return this.articleDetailsId;
    }

    setCategoryId(catId){
        this.categoryId = catId;
    }

    getCategoryId(){
        return this.categoryId;
    }

    changeStatus(data: any) {
        return this.http.post(API.CHANGE_ARTICLE_STICKY, data);
    }

    detailsById(artId) {
        return this.http.get(API.ARTICLE_DETAILS + artId);
    }
    artDetailsById(artId) {
        return this.http.get(API.ARTICLE_DETAILS_BY_ID + artId  );
    }
    detailsByCatAndArticle(categoryId, articleName, articleId) {
        return this.http.get(API.ARTICLE_DETAILS_BY_CATNAME_ARTNAME + categoryId+"/"+articleId  );
    }

    catDetailsById(catId) {
        return this.http.get(API.CATEGORY_DETAILS + catId);
    }

    listByCategory(catId, name) {
        if (catId) {
            return this.http.get(API.LIST_BY_CATEGORY + catId + "/" + name);
        } else {
            return this.http.get(API.LIST_BY_CATEGORY + 0 + "/"+name);
        }
    }
    
    getParentCategory(catId) {
        return this.http.get(API.GET_PARENT_CATEGORY + catId);
    }
    getChildCategory(catId) {
        return this.http.get(API.GET_CHILD_CATEGORY + catId);
    }

    getArticleList(filter, categoryId) {
        if (filter && categoryId) {
            return this.http.get(API.GET_ARTICLE_LIST_API + '?filter=' + JSON.stringify(filter) + '&catId=' + categoryId);
        } else if (filter && !categoryId) {
            return this.http.get(API.GET_ARTICLE_LIST_API + '?filter=' + JSON.stringify(filter));
        } else {
            return this.http.get(API.GET_ARTICLE_LIST_API);
        }
    }

    getRelatedArticles(loginuser,categoryId) {
        return this.http.get(API.GET_RELATED_ARTICLES + categoryId + "/" + loginuser);
    }

    deleteArticle(artId) {
        return this.http.delete(API.DELETE_ARTICLE + artId);
    }

    deleteCategory(catId, pid) {
        return this.http.delete(API.DELETE_CATEGORY + catId + "/" + pid);
    }

    autoAddArticle(data: any): Observable<BaseResponse<IAutoAddArticleData>> {
        return this.http.post<BaseResponse<IAutoAddArticleData>>(API.AUTO_ADD_ARTICLE, data);
    }
}

export interface ArticlesResult {
    msg: string;
    artId: number;
    url: string;
    type: string;
    artCategoryId: number;
    // FIXME
    // articleDialog: MatDialogRef<ArticleDetailsComponent> | null;
    articleDialog: MatDialogRef<unknown> | null;
}
